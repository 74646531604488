import cl from 'classnames';
import { debounce, noop } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

import { colorsV2 } from '../../colors-v2';

const StyledBaseButton = styled.button.withConfig({
  componentId: 'ButtonV2-BaseButton',
} as any)<BaseButtonProps>`
  ${space};
  position: relative;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  border: none;
  color: ${colorsV2.black100};
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0s;

  &.has-count {
    color: #d14a4a;
  }
  .count {
    position: absolute;
    top: -10px;
    right: -5px;
    border: 1px solid ${colorsV2.primary100};
    width: 24px;
    height: 24px;
    border-radius: 15px;
    font-size: 10px;
    line-height: 23px;
    color: ${colorsV2.white100};
    background: ${colorsV2.primary100};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export interface BaseButtonProps extends React.HTMLAttributes<HTMLButtonElement>, SpaceProps {
  disabled?: boolean;
  style?: React.CSSProperties;
  count?: string;
  onClickDebounce?: (...args: any[]) => void;
  debounceInterval?: number;
  loading?: boolean;
  htmlType?: 'submit' | 'reset' | 'button';
}

export const BaseButton = (props: BaseButtonProps) => {
  const {
    children,
    disabled = false,
    className = '',
    count = undefined,
    onClick = noop,
    debounceInterval = 0,
    onClickDebounce = noop,
    loading,
    htmlType = 'button',
    ...rest
  } = props;

  const onClickDebounceHandle = React.useMemo(
    () => debounce(onClickDebounce, debounceInterval),
    [debounceInterval, onClickDebounce]
  );

  const onClickHandle = React.useCallback(
    (e) => {
      onClickDebounceHandle();
      return onClick(e);
    },
    [onClick, onClickDebounceHandle]
  );

  return (
    <StyledBaseButton
      type={htmlType}
      className={cl(`btn ${className}`, {
        'has-count': Boolean(count),
      })}
      disabled={loading || disabled}
      onClick={onClickHandle}
      {...rest}
    >
      {children}
      {Boolean(count) && <div className="count">{count}</div>}
    </StyledBaseButton>
  );
};
